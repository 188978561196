.form__register{
    width: 50%;
    max-width: 780px;
    margin: 100px auto;
    padding: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 1024px){
        margin-top: 100px;
    }
    @media screen and (max-width: 992px){
        width: 90%;
    }
    // p{
    //     margin: -10px 0 5px 0;
    //     color:white;
    //     padding: 5px;
    //     background-color: #f50057;
    //     border-radius: 0.845rem;
    //     font-size: 0.8rem;
    //     width: 20%;
    //     @media screen and (max-width:1280px){
    //         width: 160px;
    //     }
    //     float:right;
    //     text-align: center;
    // }
    h1{
        width: 100%;
        margin: 0;
        color: #3f4d55;
        text-align: center;
    }
    .button__register{
        margin-right: 20px;
        color: white;
        background-color: #0a50e6;
        padding: 6px 16px;
        font-size: 0.875rem;
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1.75;
        border-radius: 4px;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        cursor: pointer;
        outline: 0;
        border: 0;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    }
    .button__register:hover{
        box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
        background-color: #89a1f0;
    }
    .button__form{
        background-color: #f50057; 
        color: white;
    }
    .button__form:hover{
        background-color: #f52b72;
    }
    .button__div{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .input__form{
        width: 100%;
        // margin-bottom: 20px;
        // margin-top: 5px;
        @media only screen and (max-width: 992px){
            margin-bottom: 20px;
        }
    }
    .label__input{
        color: #3f4d55;
        font-weight: bold;
        font-size: 1.1rem;
        margin-bottom: 10px;
    }
    .hr__divider{
        border: 1px solid rgb(235, 234, 234);
        width: 98%;
        margin: 13px auto;
        display:block;
        @media only screen and (max-width: 992px){
            display: none;
        }
    }
}

