.loader {
  content: "";
  display: table;
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  background-size: 64px 64px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../public/favicon-white.png");
  z-index: 1;
}